.MuiInputLabel-root{
    @media (max-width: 767px) {
      font-size: 16px !important;
    }
}

.MuiInputLabel-formControl{
    @media (max-width: 767px) {
        font-size: 15px !important;
      }
}

.CustomHome-image{
  height: 50%;
  @media (max-width: 767px) {
    max-width: 80%;
    }
}

.CustomHome-div{
  height: auto;
  @media (max-height: 600px) {
    height: 75vh;
    }
}